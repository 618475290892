import { AddToPhotos } from '@mui/icons-material'
import { Grid, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import { BASE_URL } from '../../Api'

const BackPaper = styled(Paper)({
  height: "600px",
  borderRadius: "0",
  padding: "20px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const ReasonDetail = () => {
  let point = [];
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [det, setDet] = useState({});
  const [edit, setEdit] = useState(false)
  const [image, setImage] = useState("/images/noImage.jpg")
  const [image2, setImage2] = useState("")
  const [pointArray, setPointArray] = ([{}])
  const [obj, setObj] = useState({
    name: "",
    subTag: "",
    shortDes: "",
    longDes: "",
  })

  const imageChange = (e) => {
    setImage2(e.target.files[0])
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
    }
  }

  const handleEdit = () => {
    setEdit(true)
  }

  const addata = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    })
  }

  const getAll = async () => {
    const result = await APIFunctions.article(location.state.id);
    if (result.data.code === 200) {
      setIsLoading(false)
      setObj(result.data.data)
      setImage(`${BASE_URL}uploads/images/articles/${result.data.data.image}`)
    } else if (result.data.code === 201) {
      toast.error("error are coming for fetching images")
    } else if (result.data.code === 203) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/admin")
      toast.info("Session expired")
    }
  }

  point.push(obj.points)
  
  const update = async () => {
    let id = location.state.id
    const formData = new FormData()
    formData.append("name", obj.name)
    formData.append("subTag", obj.subTag)
    formData.append("shortDes", obj.shortDes)
    formData.append("longDes", obj.longDes)
    formData.append("image", image2)
    const result = await APIFunctions.editArticle(id, formData);
    if (result.data.code === 200) {
      setIsLoading(false)
      setEdit(false)
      toast.success("Updated successfully!")
    } else if (result.data.code === 201) {
      toast.error("error are coming for fetching images")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/admin")
      toast.info("Session expired")
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getAll()
  }, [])

  return (
    <>
      <Toptag />
      <ToastContainer />
      <BackPaper>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>Article Details</Grid>
          <Grid item>
            {!edit ?
              <button className='newBtn' onClick={handleEdit}>Update</button>
              :
              null
            }
          </Grid>
        </Grid>
        <hr></hr>

        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={6} lg={4} item >
            <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <img src={image} style={{ width: "300px", height: "200px" }} alt="" className='imgCont1' />
              </Grid>
              <Grid item>
                {edit ? <div className="image-upload">
                  <label htmlFor="image">
                    <Tooltip title="Change pic"><AddToPhotos style={{ fontSize: "30px", color: "grey", cursor: "pointer" }} /></Tooltip>
                  </label>

                  <input id="image" onChange={imageChange} type="file" accept="image/*" />
                </div> : null}
              </Grid>
              <Grid item>
                {edit ? <button className='newBtn' onClick={update}>Save</button> : null}
              </Grid>

            </Grid>
          </Grid><Grid xs={12} sm={12} md={6} lg={8} item>
            <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Grid item>Title</Grid>
              <Grid item style={{ width: "100%" }}>
                <input type="text" disabled={!edit} value={obj.name} name="name" onChange={addata} className="detailBarInput" />
              </Grid>
              <Grid item>Topic</Grid>
              <Grid item style={{ width: "100%" }}>
                <input type="text" disabled={!edit} value={obj.subTag} name="subTag" onChange={addata} className="detailBarInput" />
              </Grid>
              <Grid item>Short Description</Grid>
              <Grid item style={{ width: "100%" }}>
                <input type="text" disabled={!edit} value={obj.shortDes} name="shortDes" onChange={addata} className="detailBarInput" />
              </Grid>
{/*              <Grid item>Solution Points</Grid>
              {obj.points && obj.points.map((element, index) => {
                return (
                  <>
                    <Grid item>{index+1}.Problem Name</Grid>
                    <Grid item style={{ width: "100%" }}>
                      <input type="text" value={element.problem} className="detailBarInput" />
                    </Grid>
                    <Grid item>Solution</Grid>
                    <Grid item style={{ width: "100%" }}>
                      <input type="text" value={element.solution} className="detailBarInput" />
                    </Grid>
                  </>
                )

              })}*/}
              <Grid item>Long Description</Grid>
              <Grid item style={{ width: "100%" }}>
                <textarea style={{ height: "400px" }} type="text" disabled={!edit} value={obj.longDes} name="longDes" onChange={addata} className="detailBarInput" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </BackPaper >
    </>
  )
}

export default ReasonDetail