import React, { useState } from 'react'
import { Grid, Paper, styled } from '@mui/material'
import Toptag from '../../components/topTag/Toptag'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import {  useNavigate } from 'react-router-dom'
import APIFunctions from '../../ApiFunction'


const BackPaper =  styled(Paper)({
  height:"440px",
  padding:"20px",
  overflow:"auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const NavPaper =  styled(Paper)({
  padding:"20px",
  borderRadius:"0",
  backgroundColor:"white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const AddnewF = () => {
  const [obj,setObj] = useState({title:'',message:''})
  const navigate = useNavigate();
  const [err,setErr] = useState(false)
 
  const addata = (e) => {
    setObj({
        ...obj,
        [e.target.name] : e.target.value.trim(),
     })
  }
 

  const handleSubmit = async (event) =>{
    event.preventDefault();
    if(obj.title===''||obj.message===''){
      setErr(true);
    }else {
      const result = await APIFunctions.createFaq(obj);
      if(result.data.code===200){
        toast.success("created succesfully")
        navigate('/admin/faq')
      }else if(result.data.code===201){
        toast.error("Faq already exist")
      }else if(result.data.code===204){
        toast.warning("You can add only 5 faq")
      }else if(result.data.code===401){
        localStorage.clear('admintoken');
        sessionStorage.clear('admintoken');
        navigate("/admin")
        toast.info("Session expired")
      }
    }
  };

 


  return (
    <>
    <Toptag />
    <ToastContainer />
    <NavPaper>
    <Grid  container  direction="row"   justifyContent="space-between" alignItems="center">
        <div style={{fontSize:"15px",fontWeight:"400",color:"grey"}}>Add New Faq</div>
         <div>
    
         </div>   
    </Grid>
    </NavPaper>
    
    <BackPaper>
    <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
   <Grid item>Title</Grid>
   <Grid item style={{ width: "100%" }}>
    <textarea type="text" style={{height:"100px"}} name="title" id="title" onChange={addata} placeholder="Title" className="detailBarInput" />
    {err && obj.title===''?<div className="errText">Title is required</div>:null}
   </Grid>
   <Grid item>Message</Grid>
    <Grid item style={{ width: "100%" }}>
    <textarea type="text" style={{height:"100px"}}  name="message" id="message" onChange={addata} placeholder="Message" className="detailBarInput" />
    {err && obj.message===''?<div className="errText">Message is required</div>:null}
    </Grid>
    <Grid item><button className='newBtn' onClick={handleSubmit} >Add</button></Grid>  
   </Grid>
    </BackPaper>
    </>
  )
}

export default AddnewF