import {  Grid, Paper, Tooltip } from '@mui/material'
import { AddToPhotos } from '@mui/icons-material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import Loader from '../../components/loader/Loader'
import { BASE_URL } from '../../Api'


const BackPaper = styled(Paper)({
  height: "600px",
  borderRadius: "0",
  padding: "20px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const PostDetail = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState("/images/noImage.jpg")
  const [image2, setImage2] = useState("")
  const [obj, setObj] = useState({
    userId: "",
    carMake: "",
    carName: "",
    carModel: "",
    title: "",
    description: "",
  })

  const addata = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    })
  }

  const getAll = async () => {
    setIsLoading(true)
    const result = await APIFunctions.postDetails(location.state.id);
    if (result.data.code === 200) {
      setIsLoading(false)
      setObj(result.data.data)
      setImage(`${BASE_URL}uploads/${result.data.data.image}`)
    } else if (result.data.code === 201) {
      toast.error("error are coming for fetching images")
    } else if (result.data.code === 203) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }

  const handleEdit = () => {
    setEdit(true)
  }

  const update = async () => {
    let id = location.state.id
    const formData = new FormData()
    formData.append("carMake", obj.carMake)
    formData.append("carName", obj.carName)
    formData.append("carModel", obj.carModel)
    formData.append("title", obj.title)
    formData.append("description", obj.description)
    formData.append("image", image2)
    const result = await APIFunctions.editPost(id, formData);
    if (result.data.code === 200) {
      setIsLoading(false)
      setEdit(false)
      toast.success("Updated successfully!")
    } else if (result.data.code === 201) {
      toast.error("error are coming for fetching images")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/admin")
      toast.info("Session expired")
    }
  }

  const imageChange = (e) => {
    setImage2(e.target.files[0])
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
    }
  }

  useEffect(() => {
    getAll()
  }, [])

  return (
    <>
      {isLoading ?
        <Loader />
        :
        <>
          <Toptag />
          <ToastContainer />
          <BackPaper>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>Post Details</Grid>
              <Grid item>
                {!edit ?
                  <button className='newBtn' onClick={handleEdit}>Update</button>
                  :
                  null
                }
              </Grid>
            </Grid>
            <hr></hr>

            <Grid container spacing={2}>
              <Grid xs={12} sm={12} md={6} lg={5} item >
                <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
                  <Grid item>
                    <img src={image} className='detailPageImage' alt="postImage" />
                  </Grid>
                  <Grid item>
                    {edit ? <div className="image-upload">
                      <label htmlFor="image">
                        <Tooltip title="Change pic"><AddToPhotos style={{ fontSize: "30px", color: "grey", cursor: "pointer" }} /></Tooltip>
                      </label>
                      <input id="image" onChange={imageChange} type="file" accept="image/*" />
                    </div> : null}
                  </Grid>
                  <Grid item>
                    {edit ? <button className='newBtn' onClick={update}>Save</button> : null}
                  </Grid>

                </Grid>
              </Grid><Grid xs={12} sm={12} md={6} lg={7} item>
                <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                  <Grid item>userId</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled value={obj.userId} name="userId" className="detailBarInput" />
                  </Grid>
                  <Grid item>Make</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.carMake} name="carMake" onChange={addata} className="detailBarInput" />
                  </Grid>
                  <Grid item>Model</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.carName} name="carName" onChange={addata} className="detailBarInput" />
                  </Grid>
                  <Grid item>Year</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.carModel} name="carModel" onChange={addata} className="detailBarInput" />
                  </Grid>
                  <Grid item>Title</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <input type="text" disabled={!edit} value={obj.title} name="title" onChange={addata} className="detailBarInput" />
                  </Grid>
                  <Grid item>Description</Grid>
                  <Grid item style={{ width: "100%" }}>
                    <textarea type="text" disabled={!edit} value={obj.description} name="description" style={{ height: "200px" }} onChange={addata} className="detailBarInput" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </BackPaper>
        </>}
    </>
  )
}

export default PostDetail