import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import Loader from '../../components/loader/Loader'
import { BASE_URL } from '../../Api'
import { BackPaper, BusinessLikesFoll, BusinessLink, BusinessName, DetailLabel, DetailText } from '../../components/Styles'
import moment from 'moment'
import { Grid, Tooltip } from '@mui/material'
import { AccountCircleOutlined, Business, Call, Category, Description, EmailOutlined, OpenInNew } from '@mui/icons-material'

const DirectoryDetails = () => {
    const router = useParams()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [obj, setObj] = useState({})
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));




    const getAll = async () => {
        try {
            let obj = {
                id: router.id,
            }
            const result = await APIFunctions.viewDirectoryDetails(obj);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate('/admin')
                    toast.info("Session expired")
                }
                toast.error(result.data.messsage)
            } else {
                setObj(result.data.data)
                await delay(1000);
                setIsLoading(false)
            }

        } catch (error) {
            console.log(error.message)
        }
    }



    useEffect(() => {
        getAll()
    }, [])

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <ToastContainer />
                    <BackPaper>
                        <div className='d-flex justify-content-between align-items-center p-2'>
                            <div className='detailHeading'>Business Details</div>
                            <div className='userDate'>This Profile is created on {moment(obj.createdAt).format('LLL')}</div>
                        </div>
                        <div className='d-flex p-2  flex-row gap-3 justify-content-start align-items-center'>
                            {obj.business_logo !== '' ? (
                                <img src={`${BASE_URL}uploads/images/businessLogos/${obj.business_logo}`} alt="user" className='user-detailsImage' />
                            ) : (
                                <img src="/images/blank_pic.png" className='user-detailsImage' alt="" />

                            )}
                            <div className='d-flex flex-column gap-2 justify-content-start align-items-start'>
                                <BusinessName>{obj.business_name} {obj.isVerified ? <Tooltip title="Verified Business"><img src="/images/emailVerifiedIcon.svg" alt="emailVerifiedIcon.svg" /></Tooltip> : null}</BusinessName>
                                <a href={obj.business_url && obj.business_url.startsWith('https') ? obj.business_url : `https://${obj.business_url}/`} target="_blank" rel="noopener noreferrer"><BusinessLink>{obj.business_url}<OpenInNew style={{ fontSize: "13px" }} /></BusinessLink></a>
                                <div className='d-flex flex-row gap-2 justify-content-start align-items-start'>
                                    <BusinessLikesFoll>Likes {obj.likes && obj.likes.length}</BusinessLikesFoll>
                                    <BusinessLikesFoll>Followers {obj.followers && obj.followers.length}</BusinessLikesFoll>
                                    <BusinessLikesFoll>Following {obj.following && obj.following.length}</BusinessLikesFoll>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row'>
                            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4'>
                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ borderRight: "2px solid lightgrey" }} >
                                    <Grid item>
                                        <DetailLabel><Business className='detailIcon' />Address</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <DetailText>{obj.business_address}</DetailText>
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><Description className='detailIcon' />Description</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <DetailText>{obj.business_description}</DetailText>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-8'>
                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" >
                                    <Grid item>
                                        <DetailLabel><AccountCircleOutlined className='detailIcon' />Name</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <input type="text" disabled value={obj.business_name} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><EmailOutlined className='detailIcon' />Email</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <input type="text" disabled value={obj.business_email} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><Category className='detailIcon' />Business Category</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <input type="text" disabled value={obj.business_category} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><Call className='detailIcon' />Contact</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <input type="text" disabled value={obj.business_contact} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><Business className='detailIcon' />Address</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <textarea style={{ height: "100px" }} type="text" disabled value={obj.business_address} className="detailBarInput" />
                                    </Grid>
                                    <Grid item>
                                        <DetailLabel><Description className='detailIcon' />Description</DetailLabel>
                                    </Grid>
                                    <Grid item className='w-100'>
                                        <textarea style={{ height: "100px" }} type="text" disabled value={obj.business_description} className="detailBarInput" />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>




                    </BackPaper>
                </>}

        </>
    )
}

export default DirectoryDetails