import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, Publish, RemoveCircleOutline, RemoveRedEye, Search, Unpublished, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunction from "../../ApiFunction"
import { elements } from 'chart.js'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../Api'

const NavPaper = styled(Paper)({
  padding: "10px",
  borderRadius: "0",
  backgroundColor: "white",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const BackPaper = styled(Paper)({
  height: "580px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const Reason = () => {
  const isLoading = useSelector(state => state.count.isLoading);
  const error = useSelector(state => state.count.error);
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [delopen, setDelOpen] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()




  if (error === 401) {
    localStorage.clear('admintoken');
    sessionStorage.clear('admintoken');
    navigate("/admin")
    toast.info("Session expired")
  }


  const handleBlockOpen = (id, status) => {
    setId(id)
    setStatus(status)
    setOpen(true);
  };

  const handleBlock = () => {
    setOpen(false);
  };

  const handleDeleteOpen = (id) => {
    setId(id)
    setDelOpen(true);
  };

  const handleDelete = () => {
    setDelOpen(false);
  };






  const getAll = async () => {
    const result = await APIFunction.allArticle();
    if (result.data.code === 200) {
      setAll(result.data.data)
    } else if (result.data.code === 401) {
      localStorage.clear('x_admintoken');
      sessionStorage.clear('x_admintoken');
      navigate("/admin")
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    getAll()
  }, [])


  const PublishArticle = async (id) => {
    const result = await APIFunction.hideArticle(id);
    if (result.data.code === 200) {
      setOpen(false);
      getAll();
      toast.success("Successfully updated!");
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('x_admintoken');
      sessionStorage.clear('x_admintoken');
      navigate("/admin")
      toast.info("Session expired")
    }
  }

  const deleteArticle = async (id) => {
    const result = await APIFunction.deleteArticle(id);
    if (result.data.code === 200) {
      setDelOpen(false);
      getAll();
      toast.success("Successfully deleted!");
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('x_admintoken');
      sessionStorage.clear('x_admintoken');
      navigate("/admin")
      toast.info("Session expired")
    }
  }

  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer />
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Article: {all.length} </div></Grid>
                  <Grid item>
                    <button className='newBtn' onClick={() => navigate('/admin/add-new-reason-solution')}>ADD NEW</button>
                  </Grid>
                </Grid>
              </div>
            </NavPaper>
            <BackPaper>
              {all.length === 0 ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">User not found!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Index</TableCell>
                        <TableCell >Image</TableCell>
                        <TableCell >Title</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        let date, datearr, newDate;
                        try {
                          date = new Date(element.createdAt);
                          datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
                          newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                            datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                            date.toISOString().substring(0, 10).split("-")[0];
                        } catch (err) {
                          console.error(err);
                          // console.log('element.createdAt', element.createdAt);
                          newDate = 'InvalidDate';
                        }
                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {element.image === '' ? (
                                <img src="/images/noImage.jpg" style={{ width: "70x", height: "45px" }} alt="articleIMg" />
                              ) : (
                                <img src={`${BASE_URL}uploads/images/articles/${element.image}`} style={{ width: "70x", height: "45px" }} alt="articleIMg" />

                              )}
                            </TableCell>
                            <TableCell>{element.name.length > 100 ? <>{element.name.slice(0, 200)}...</> : element.name}</TableCell>
                            <TableCell>{newDate}</TableCell>
                            <TableCell align="right">
                              <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="view profile" >
                                    <NavLink to='/admin/reason-solution/details-reason-solution' state={{ id: element._id }} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Tooltip title={element.isPublish ? 'UN-PUBLISH' : 'PUBLISH'} >
                                    {element.isPublish === false ?
                                      <Publish className="blockIcon" style={{ color: "green" }} onClick={() => { handleBlockOpen(element._id, element.isPublish) }} />
                                      :
                                      <Unpublished className="blockIcon" style={{ color: "red" }} onClick={() => { handleBlockOpen(element._id, element.isPublish) }} />
                                    }
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Tooltip title='Delete Article' >
                                    <DeleteOutline className="blockIcon" style={{ color: "red" }} onClick={() => { handleDeleteOpen(element._id) }} />

                                  </Tooltip>
                                </Grid>
                                {/*<Grid item></Grid>*/}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }
              {/* dilogue for block user */}
              <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {status === false ? ("Are you sure want to publish this article?") : ("Are you sure want to un-publish this artcile?")}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleBlock}>Cancel</Button>
                  <Button onClick={() => { PublishArticle(id) }} >{status === false ? 'PUBLISH' : 'UN-PUBLISH'}</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for delete user */}
              <Dialog open={delopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to delete this article from website?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleDelete}>No</Button>
                  <Button onClick={() => { deleteArticle(id) }} >Yes</Button>
                </DialogActions>
              </Dialog>


            </BackPaper>


          </>

        )}

    </>
  )
}

export default Reason









