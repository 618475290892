import { Block, DeleteOutline, DoneAll, EditOutlined, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import APIFunctions from '../../ApiFunction'
import { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'






const Plans = () => {
  const router = useLocation();
  const [all, setAll] = useState([]);
  const [user, setUser] = useState()
  const navigate = useNavigate();
  const [row, setRow] = useState('10');
  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState('0')
  const [edit, setEdit] = useState(false)
  const [obj, setObj] = useState({ limits: 0, price: 0 })

  const addData = (e) => {
    if (!isNaN(e.target.value.trim())) {
      setObj({
        ...obj,
        [e.target.name]: e.target.value.trim(),
      })
    }
  };


  const getAll = async () => {
    const result = await APIFunctions.getAllPricing();
    if (result.data.code === 200) {
      setAll(result.data.data)
      setIsLoading(false)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }

  const updatePrice = async () => {
    const result = await APIFunctions.editPricing(obj);
    if (result.data.code === 200) {
      getAll()
      setEdit(false)
      setObj({})
      toast.success('Update successfully!')
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }




  const editHandle = (data) => {
    setEdit(true)
    setObj(data)
  }

  const handleClose = () => {
    setEdit(false)
  }


  useEffect(() => {
    getAll()
    setUser(all.length)
    setIsLoading(true)
  }, [])




  const handleChange = (e) => {
    if (e.target.value === user) {
      setSkip(0)
      setRow(user)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }
  return (
    <>
      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer />
            <BackPaper>
              {all.length === 0 ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">Data not found!</div></Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell >Index</TableCell>
                        <TableCell align='center'>Plan Type</TableCell>
                        <TableCell align='center'>Price</TableCell>
                        <TableCell align='center'>Queries</TableCell>
                        <TableCell align='center'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {all.map((element, index) => {
                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell align='center' style={{ textTransform: 'uppercase', fontWeight: "bold" }}>{element.type}</TableCell>
                            <TableCell align='center'>${element.price}</TableCell>
                            <TableCell align='center'>{element.limits === 0 ? 'Not found' : element.limits}</TableCell>
                            <TableCell align='center'>
                              <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="Edit plan" >
                                    <EditOutlined className="userListDelete" onClick={() => editHandle(element)} />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }

            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(user / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
            <Dialog open={edit} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle>Edit pricing of plans!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You can edit this pricing and their details. Please note that all pricing and other details will be displayed on the website, and all pricing are dynamically functional.
                </DialogContentText>
                <div style={{ marginTop: "30px" }}></div>
                <TextField autoFocus required margin="dense" disabled label="Type" value={obj.type}
                  fullWidth variant="standard" />
                <div style={{ marginTop: "30px" }}></div>
                <TextField autoFocus required margin="dense"
                  id="price" name="price" label="Price($)" type="email" value={obj.price} onChange={addData}
                  fullWidth variant="standard" />
                <div style={{ marginTop: "30px" }}></div>
                <TextField autoFocus required margin="dense"
                  id="limits" name="limits" label="Limits" type="email" onChange={addData} disabled={obj.limits === 0?true:false} value={obj.limits === 0 ? 'Not Required' : obj.limits}
                  fullWidth variant="standard" />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" onClick={updatePrice}>Save</Button>
              </DialogActions>
            </Dialog>
          </>

        )}
    </>
  )
}

export default Plans