import './card.css';
import {
  Person4,
  PersonOff,
  Paid,
  Subscriptions,
  WorkspacePremium,
  TrendingUp,
  Message,
  DoneAll,
  Report,
  Summarize,
  PeopleOutlineOutlined,
  GroupRemoveOutlined,
  PaymentsOutlined,
  SubscriptionsOutlined,
  SummarizeOutlined,
  ReportOutlined,
} from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import Toptag from '../topTag/Toptag';
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import APIFunction from '../../ApiFunction';
import Loader from '../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setCount } from '../../slice/count';

export default function Card() {
  const navigate = useNavigate();
  const disptch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.userinfo.user);

  const usersCount = useSelector((state) => state.count.counts.allUsersCount);
  const removedUsersCount = useSelector(
    (state) => state.count.counts.removedUsersCount
  );
  const premiumUsersCount = useSelector(
    (state) => state.count.counts.premiumUsersCount
  );
  const purchaseCount = useSelector(
    (state) => state.count.counts.purchaseCount
  );
  const membershipCount = useSelector(
    (state) => state.count.counts.membershipCount
  );
  const contactUsCount = useSelector(
    (state) => state.count.counts.contactUsCount
  );
  const reportPostCount = useSelector(
    (state) => state.count.counts.reportPostCount
  );
  const reportCommentCount = useSelector(
    (state) => state.count.counts.reportCommentCount
  );

  const getCounts = async () => {
    try {
      let result = await APIFunction.countUser();
      if (!result.data.status) {
        if (result.data.code === 401) {
          localStorage.clear('admintoken');
          sessionStorage.clear('admintoken');
          navigate('/admin');
          toast.info('Session expired');
        }
        toast.error(result.data.message);
      } else {
        disptch(setCount(result.data.result));
        localStorage.setItem('count', JSON.stringify(result.data.result));
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCounts();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Toptag />
          <div className='container'>
            <div className='cardWrapper'>
              {user.role === 'sub-admin' ? null : (
                <>
                  <NavLink to='/admin/users' className='link'>
                    <div
                      className='cardContainer'
                      style={{ backgroundColor: '#669bbc' }}
                    >
                      <div className='cardTopLogo'>
                        <PeopleOutlineOutlined className='cardLogo' />
                      </div>
                      <span className='cardTitle'>Users</span>
                      <span className='userCount'>
                        <TrendingUp className='trendingLogo' />
                        {usersCount}
                      </span>
                    </div>
                  </NavLink>
                  <NavLink to='/admin/removed-users' className='link'>
                    <div
                      className='cardContainer'
                      style={{ backgroundColor: '#c3a995' }}
                    >
                      <div className='cardTopLogo'>
                        <GroupRemoveOutlined className='cardLogo' />
                      </div>
                      <span className='cardTitle'>Removed Users</span>
                      <span className='userCount'>
                        <TrendingUp className='trendingLogo' />
                        {removedUsersCount}
                      </span>
                    </div>
                  </NavLink>
                  <NavLink to='/admin/premium-users' className='link'>
                    <div
                      className='cardContainer'
                      style={{ backgroundColor: '#463f3a' }}
                    >
                      <div className='cardTopLogo'>
                        <WorkspacePremium className='cardLogo' />
                      </div>
                      <span className='cardTitle'>Premium Users</span>
                      <span className='userCount'>
                        <TrendingUp className='trendingLogo' />
                        {premiumUsersCount}
                      </span>
                    </div>
                  </NavLink>
                </>
              )}
              {user.role === 'sub-admin' ? null : (
                <NavLink
                  to='/admin/all-transactions'
                  state='purchase'
                  className='link'
                >
                  <div
                    className='cardContainer'
                    style={{ backgroundColor: '#6a994e' }}
                  >
                    <div className='cardTopLogo '>
                      <PaymentsOutlined className='cardLogo' />
                    </div>
                    <span className='cardTitle'>Payments</span>
                    <span className='userCount'>
                      <TrendingUp className='trendingLogo' />
                      {purchaseCount}
                    </span>
                  </div>
                </NavLink>
              )}
              <NavLink to='/admin/subscriptons' className='link'>
                <div
                  className='cardContainer'
                  style={{ backgroundColor: '#ad2831' }}
                >
                  <div className='cardTopLogo'>
                    <SubscriptionsOutlined className='cardLogo' />
                  </div>
                  <span className='cardTitle'>Subscriptions</span>
                  <span className='userCount'>
                    <TrendingUp className='trendingLogo' />
                    {membershipCount}
                  </span>
                </div>
              </NavLink>
              <NavLink to='/admin/contact-us-messages' className='link'>
                <div
                  className='cardContainer'
                  style={{ backgroundColor: '#2b9348' }}
                >
                  <div className='cardTopLogo'>
                    <Message className='cardLogo' />
                  </div>
                  <span className='cardTitle'>Inbox</span>
                  <span className='userCount'>
                    {contactUsCount > 0 ? (
                      <div className='glowText'>
                        Message ({contactUsCount}){' '}
                      </div>
                    ) : (
                      <DoneAll className='trendingLogo' />
                    )}
                  </span>
                </div>
              </NavLink>
              <NavLink to='/admin/reported-post' className='link'>
                <div
                  className='cardContainer'
                  style={{ backgroundColor: '#5a189a' }}
                >
                  <div className='cardTopLogo'>
                    <SummarizeOutlined className='cardLogo' />
                  </div>
                  <span className='cardTitle'>Reported Posts</span>
                  <span className='userCount'>
                    <TrendingUp className='trendingLogo' />
                    {reportPostCount}
                  </span>
                </div>
              </NavLink>
              <NavLink to='/admin/reported-comment' className='link'>
                <div
                  className='cardContainer'
                  style={{ backgroundColor: '#3c096c' }}
                >
                  <div className='cardTopLogo'>
                    <ReportOutlined className='cardLogo' />
                  </div>
                  <span className='cardTitle'>Reported Comments</span>
                  <span className='userCount'>
                    <TrendingUp className='trendingLogo' />
                    {reportCommentCount}
                  </span>
                </div>
              </NavLink>
            </div>
          </div>
        </>
      )}
    </>
  );
}
