import { AddCircleOutline, AddToPhotos, Cancel, ElevenMp } from '@mui/icons-material'
import { Dialog, Grid, IconButton, Paper, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import { elements } from 'chart.js'


const BackPaper = styled(Paper)({
  height: "600px",
  borderRadius: "0",
  padding: "20px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})
const AddReason = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false)
  const [create, setCreate] = useState(false);
  const [all, setAll] = useState([]);
  const [image, setImage] = useState("")
  const [image2, setImage2] = useState("")
  const [obj, setObj] = useState({ name: "", shortDes: "", longDes: "", subTag: "",tagName:"" })
  // const [points, setPoints] = useState([
  //   {problem:"",solution:""}
  // ]);


  // const handleAddPoint = () => {
  //   setPoints([...points, {problem:"",solution:""}]);
  // };

  // const handleCancelInput = (index) => {
  //   const newPoints = [...points];
  //   newPoints.splice(index, 1);
  //   setPoints(newPoints);
  // };

  // const handleChange = (event, index,field) => {
  //   const newPoints = [...points];
  //   newPoints[index][field] = event.target.value;
  //   setPoints(newPoints);
  // };

  const imageChange = (e) => {
    setImage2(e.target.files[0])
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
    }
  }

  const handleUserOpen = () => {
    setCreate(true);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    if (obj.tagName === '') {
      setErr(true);
    } else {
      const result = await APIFunctions.createTopic(obj);
      if (result.data.code === 200) {
        getAll();
        setCreate(false)
        toast.success("Created succesfully!")
      } else if (result.data.code === 201) {
        toast.error("Topic already exist!")
      } else if (result.data.code === 401) {
        localStorage.clear('admintoken');
        sessionStorage.clear('admintoken');
        navigate("/admin")
        toast.info("Session expired")
      }
    }
  };

  const getAll = async () => {
    const result = await APIFunctions.allTopic();
    if (result.data.code === 200) {
      setAll(result.data.data)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/admin")
      toast.info("Session expired")
    }
  }


  const addata = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    })
  }

  const update = async () => {
    const formData = new FormData()
    formData.append("name", obj.name)
    formData.append("shortDes", obj.shortDes)
    formData.append("subTag", obj.subTag)
    formData.append("longDes", obj.longDes)
    // const pointsJSON = JSON.stringify(points);
    // formData.append("points", pointsJSON)
    formData.append("image", image2)
    const result = await APIFunctions.createArticle(formData,{});
    if (result.data.code === 200) {
      setIsLoading(false)
      toast.success("Added successfully!")
      navigate("/admin/reason-solution")
    } else if (result.data.code === 201) {
      toast.error("error are coming for fetching images")
    } else if (result.data.code === 205) {
      toast.warning(result.data.message)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate("/admin")
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    setIsLoading(true)
    getAll()
  }, [])

  return (
    <>
      <Toptag />
      <ToastContainer />
      <BackPaper>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item style={{ fontSize: "20px", fontWeight: "400", color: "grey" }}>Add New Article</Grid>

        </Grid>
        <hr></hr>

        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={6} lg={4} item >
            <Grid container spacing={3} direction="column" justifyContent="flex-start" alignItems="center">
              <Grid item>
                {image ?
                  <img src={image} className='imgCont1' alt="" />
                  : <img src='/images/noImage.jpg' alt="balnk_image" className='imgCont1' />}

              </Grid>
              <Grid item>
                <div className="image-upload">
                  <label htmlFor="image">
                    <Tooltip title="Change user profile pic"><AddToPhotos style={{ fontSize: "30px", color: "grey", cursor: "pointer" }} /></Tooltip>
                  </label>
                  <input id="image" onChange={imageChange} type="file" accept="image/*" />
                </div>
              </Grid>
              <Grid item>
                <button className='newBtn' onClick={update}>Add to Website</button>
              </Grid>
            </Grid>
          </Grid><Grid xs={12} sm={12} md={6} lg={8} item>
            <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
              <Grid item>Title</Grid>
              <Grid item style={{ width: "100%" }}>
                <input type="text" id="name" name="name" onChange={addata} className="detailBarInput" />
              </Grid>
              <Grid item>Topic <span onClick={handleUserOpen} style={{ cursor: 'pointer', fontSize: "14px", color: "#8F00FF" }}>Create New</span></Grid>
              <Grid item style={{ width: "100%" }}>
                <select className="model-selector" onChange={addata} name="subTag">
                  <option value="">Click to Select Topic</option>
                  {all.map((element, index) => (
                    <option value={element.tagName} key={index + 1}>{element.tagName}</option>
                  ))}
                </select>

              </Grid>
              <Grid item>Short Description</Grid>
              <Grid item style={{ width: "100%" }}>
                <textarea style={{ height: "100px" }} type="text" name="shortDes" id="shortDes" onChange={addata} className="detailBarInput" />
              </Grid>
{/*              <Grid item>Solution Points</Grid>
              <Grid item style={{ width: "100%" }}>
                {points.map((point, index) => (
                  <>
                  <Grid item>Add Problem</Grid>
                  <div style={{ position:'relative' }}>
                    <input type="text" style={{ marginBottom: '5px', flex: 1 }}
                      className="detailBarInput" onChange={(event) => handleChange(event, index,'problem')}
                      id={`point-${index}`} name={`point-${index}`} value={point.problem} />
                    <Cancel onClick={() => handleCancelInput(index)} style={{ cursor: "pointer",position:'absolute',right:"-12px",top:"-6px" }} />
                  </div>
                  <Grid item>Add Solution</Grid>
                  <input
                  type="text" style={{ marginBottom: '5px', flex: 1 }}
                  className="detailBarInput" onChange={(event) => handleChange(event, index,'solution')}
                  id={`point-${index}`} name={`point-${index}`} value={point.solution}/>
                  </>
                ))}
              </Grid>
              <Grid item>
                <button className='newBtn' onClick={handleAddPoint}>Add Points</button>
              </Grid>*/}
              <Grid item>Long Description</Grid>
              <Grid item style={{ width: "100%" }}>
                <textarea style={{ height: "400px" }} type="text" onChange={addata} id="longDes" name="longDes" className="detailBarInput" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog open={create} aria-labelledby="responsive-dialog-title">
          <div className="popUpCreate">
            <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
              <Cancel style={{ fontSize: "25px", color: "white", cursor: "pointer" }} onClick={() => setCreate(false)} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className='popHeading'>Create Tag For CARFO</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

              <div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                  <div className="popText">Tag Name</div>
                  <div><input type="text" className="popInput" name="tagName" id="tagName" onChange={addata} /></div>
                  {err && obj.tagName === '' ? <div className="errText">Field should not be empty!</div> : null}
                </div>
              </div>

            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
              <div>
                <button className='popBtn' onClick={handleSubmit}>Create</button>
              </div>
            </div>
          </div>
        </Dialog>
      </BackPaper>
    </>
  )
}

export default AddReason