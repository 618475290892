import { Block, Cancel, DeleteOutline, DoneAll, FreeBreakfastSharp, RemoveCircleOutline, RemoveRedEye, Search, WorkspacePremium } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/loader/Loader'
import Toptag from '../../components/topTag/Toptag'
import "./user.css"
import APIFunction from "../../ApiFunction"
import { elements } from 'chart.js'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../Api'
import { BackPaper, NavPaper } from '../../components/Styles'
import moment from 'moment'


const AdminUsers = () => {
  const usersCount = useSelector(state => state.count.counts.allUsersCount);
  const [isLoading,setIsLoading] = useState(true) 
  const error = useSelector(state => state.count.error);
  const [all, setAll] = useState([]);
  const navigate = useNavigate();
  const [row, setRow] = useState('10');
  const [skip, setSkip] = useState('0')
  const [key, setKey] = useState("")
  const [open, setOpen] = React.useState(false);
  const [remopen, setRemOpen] = React.useState(false);
  const [id, setId] = useState()
  const [status, setStatus] = useState()
  const [err, setErr] = useState(false)
  const [create, setCreate] = useState(false);


 

  if (error === 401) {
    localStorage.clear('admintoken');
    sessionStorage.clear('admintoken');
    navigate('/admin')
    toast.info("Session expired")
  }

  const [obj, setObj] = useState(
    { name: "", email: "", password: "", confirm_password: "", isCreatedAdmin: true }
  )


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (obj.name === '' || obj.email === '' || obj.password === '' || obj.confirm_password === '') {
      setErr(true);
    } else if (!obj.email.includes('@')) {
      setErr(true);
    } else if (obj.password !== obj.confirm_password) {
      setErr(true);
    } else {
      const result = await APIFunction.createUser(obj);
      if (result.data.code === 200) {
        setCreate(false)
        getAll();
        toast.success("User created succesfully!")
      } else if (result.data.code === 201)
        toast.success("User already exist!")
    }
  };




  const addata = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value.trim(),
    })
  }
  const handleBlockOpen = (id, status) => {
    setId(id)
    setStatus(status)
    setOpen(true);
  };

  const handleBlock = () => {
    setOpen(false);
  };

  const handleRemoveOpen = (id) => {
    setId(id)
    setRemOpen(true);
  };

  const handleRemove = () => {
    setRemOpen(false);
  };

  const searchHandle = (event) => {
    const newQuery = event.target.value.trim();
    setKey(newQuery)
    setSkip(0);
  }





  const handleUserOpen = () => {
    setCreate(true);
  };



  const getAll = async () => {
    const result = await APIFunction.getAllCreatedAdmin(key, row, skip);
    if (result.data.code === 200) {
      setAll(result.data.data)
      setIsLoading(false)
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }
  useEffect(() => {
    getAll()
  }, [key, row, skip])

  const removeUser = async (id) => {
    const result = await APIFunction.removeUser(id);
    if (result.data.code === 200) {
      setRemOpen(false);
      getAll();
      toast.success("Remove Successfully!")
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }



  const BlockUser = async (id) => {
    const result = await APIFunction.blockUser(id);
    if (result.data.code === 200) {
      setOpen(false);
      getAll();
      toast.success("Successfully updated!");
    } else if (result.data.code === 201) {
      toast.error("Error. Please try again.")
    } else if (result.data.code === 401) {
      localStorage.clear('admintoken');
      sessionStorage.clear('admintoken');
      navigate('/admin')
      toast.info("Session expired")
    }
  }


  const handleChange = (e) => {
    if (e.target.value === usersCount) {
      setSkip(0)
      setRow(usersCount)
    } else {
      setRow(e.target.value)
    }
  };
  const skipPagination = (e, page) => {
    setSkip((page - 1) * row)
  }


  return (
    <>


      {
        isLoading ? (
          <Loader />) : (
          <>
            <Toptag />
            <ToastContainer />
            {all.length === 0 ?null:
            <NavPaper>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div></div>
                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Users: {usersCount}</div></Grid>
                  <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                  <Grid item>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard-label"
                        value={row}
                        onChange={handleChange}
                      >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <div className='searchBar'>
                      <input type="text" placeholder='Search... ' onChange={searchHandle} id='searchtext' className='searchBarInput' />
                      <Search className='searchIcon' />
                    </div>
                  </Grid>
                  <Grid item>
                    <button className='newBtn' onClick={handleUserOpen}>Create User</button>
                  </Grid>
                </Grid>

              </div>
            </NavPaper>}
            <BackPaper>
              {all.length === 0  ?
                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                  <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                  <Grid item><div className="errorMessage">Users Not Created yet!</div></Grid>
                  <Grid item>
                    <button className='newBtn' onClick={handleUserOpen}>Create User</button>
                  </Grid>
                </Grid> :
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell >Name</TableCell>
                        <TableCell >Email</TableCell>
                        <TableCell>Created On</TableCell>
                        <TableCell >Points</TableCell>
                        <TableCell>Active Status</TableCell>
                        <TableCell align='center'>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {all.map((element, index) => {
                        return (<>
                          <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {element.profilePic !== '' ? (
                                <img src={`${element.profilePic && element.profilePic.startsWith("https") ? element.profilePic : BASE_URL + 'uploads/images/profiles/' + element.profilePic}`} alt="user" style={{ width: "30px", height: "30px", borderRadius: '50px' }} />
                              ) : (
                                <img src="/images/blank_pic.png" style={{ width: "30px", height: "30px", borderRadius: "50px" }} alt="" />

                              )}
                            </TableCell>
                            <TableCell>{element.name}</TableCell>
                            <TableCell>{element.email}</TableCell>
                            <TableCell>{moment(element.createdAt).format('LL')}</TableCell>
                            <TableCell >
                              {element.points}
                            </TableCell>
                            <TableCell>
                              {element.isActive === true ? (
                                <Chip label="Active" className="statusChipActive" />)
                                : (
                                  <Chip label="Blocked" className="statusChipBlock" />)
                              }</TableCell>
                            {/* <TableCell>{element.isDeletedAdmin === true ?
                              <div className='removedUser-Access'>
                                <div><i className="fa-solid fa-person-circle-xmark" style={{ fontSize: "18px" }}></i></div>
                                <div>Access Removed</div>
                              </div> :
                              '...'}</TableCell> */}
                            <TableCell>
                              <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                  <Tooltip title="view profile" >
                                    <NavLink to={'/admin/users/' + element._id} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Tooltip title={element.isActive ? 'block user' : 'unblock user'} >
                                    {element.isActive === true ?
                                      <Block className="blockIcon" style={{ color: "green" }} onClick={() => { handleBlockOpen(element._id, element.isActive) }} />
                                      :
                                      <Block className="blockIcon" style={{ color: "red" }} onClick={() => { handleBlockOpen(element._id, element.isActive) }} />
                                    }
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  {element.isDeletedAdmin === false ?
                                    <Tooltip title="Remove User From Website" >
                                      <RemoveCircleOutline className="userListDelete" onClick={() => { handleRemoveOpen(element._id) }} />
                                    </Tooltip>
                                    : null}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </>
                        )

                      })}
                    </TableBody>
                  </Table>

                </>
              }
              {/* dilogue for block user */}
              <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {status === false ? ("Are you sure want to unblock this user?") : ("Are you sure want to block this user?")}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleBlock}>Cancel</Button>
                  <Button onClick={() => { BlockUser(id) }} >{status === false ? 'unBlock' : 'Block'}</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for delete user */}
              <Dialog open={remopen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure want to remove this user from website?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleRemove}>No</Button>
                  <Button onClick={() => { removeUser(id) }} >Yes</Button>
                </DialogActions>
              </Dialog>

              {/* dilogue for create user */}
              <Dialog open={create} aria-labelledby="responsive-dialog-title">
                <div className="popUpCreate">
                  <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                    <Cancel style={{ fontSize: "25px", color: "white", cursor: "pointer" }} onClick={() => setCreate(false)} />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className='popHeading'>Create User For CARFO</div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                    <div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                        <div className="popText">Name</div>
                        <div><input type="text" className="popInput" name="name" id="name" onChange={addata} /></div>
                        {err && obj.name === '' ? <div className="errText">Name is required!</div> : null}
                      </div>
                    </div>

                    <div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                        <div className="popText">Email</div>
                        <div><input type="text" className="popInput" name="email" id="email" onChange={addata} /></div>
                        {err && obj.email === '' ? <div className="errText">Email is required!</div> : null}
                        {err && obj.email !== '' && !obj.email.includes('@') ? <div className="errText">Email must contain "@"!</div> : null}
                      </div>
                    </div>

                  </div>

                  <div style={{ display: "flex", justifyContent: "center", gap: "30px", alignItems: "center" }}>

                    <div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                        <div className="popText">Password</div>
                        <div><input type="password" className="popInput" name="password" id="password" onChange={addata} /></div>
                        {err && obj.password === '' ? <div className="errText">Password is required!</div> : null}
                      </div>
                    </div>

                    <div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "10px", alignItems: "flex-start" }}>
                        <div className="popText">Confirm Password</div>
                        <div><input type="password" className="popInput" name="confirm_password" id="confirm_password" onChange={addata} /></div>
                        {err && obj.confirm_password === '' ? <div className="errText">Confirm Password is required!</div> : null}
                        {err && obj.confirm_password !== '' && obj.confirm_password !== obj.password ? <div className="errText">Password is not matched!</div> : null}
                      </div>
                    </div>

                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                    <div>
                      <button className='popBtn' onClick={handleSubmit}>Create</button>
                    </div>
                  </div>
                </div>
              </Dialog>
            </BackPaper>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <div>
                <Pagination count={Math.ceil(usersCount / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
              </div>
            </div>
          </>

        )}

    </>
  )
}

export default AdminUsers









