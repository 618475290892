import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import APIFunction from "../../ApiFunction";
import Loader from "../../components/loader/Loader";
import Toptag from "../../components/topTag/Toptag";
import { BackPaper, NavPaper } from "../../components/Styles";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { Block, Cancel, Delete, RemoveCircleOutline, RemoveRedEye, Search } from "@mui/icons-material";
import { BASE_URL } from "../../Api";
import moment from "moment";



const Group = () => {
    const [isLoading, setLoad] = useState(true)
    const [all, setAll] = useState([]);
    const navigate = useNavigate();
    const [row, setRow] = useState('10');
    const [skip, setSkip] = useState('0')
    const [delopen, setDelOpen] = useState(false);
    const [id, setId] = useState()



    const handleDeleteOpen = (id) => {
        setId(id)
        setDelOpen(true);
    };

    const handleClose = () => {
        setDelOpen(false);
    };






    const getAll = async () => {
        const result = await APIFunction.getAllGroups(row, skip);
        if (result.data.code === 200) {
            setAll(result.data.data)
            setLoad(false)
        } else if (result.data.code === 401) {
            localStorage.clear('admintoken');
            sessionStorage.clear('admintoken');
            navigate('/admin')
            toast.info("Session expired")
        }
    }
    useEffect(() => {
        getAll()
    }, [row, skip])

    const deleteGroupData = async (id) => {
        try {
            let obj = {
                id: id
            }
            const result = await APIFunction.deleteGroup(obj);
            if (!result.data.status) {

                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate('/admin')
                    toast.info("Session expired")
                }
                toast.error(result.data.message)
            } else {
                setDelOpen(false);
                getAll();
                toast.success("Group has been delete successfully!")
            }
        } catch (error) {
            console.log(error.message)
        }
    }




    const handleChange = (e) => {
        if (e.target.value === all.length) {
            setSkip(0)
            setRow(all.length)
        } else {
            setRow(e.target.value)
        }
    };
    const skipPagination = (e, page) => {
        setSkip((page - 1) * row)
    }


    return (
        <>


            {
                isLoading ? (
                    <Loader />) : (
                    <>
                        <Toptag />
                        <ToastContainer />
                        <NavPaper>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div></div>
                                <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Total Users: {all.length}</div></Grid>
                                    <Grid item><div style={{ fontSize: "15px", fontWeight: "400", color: "grey" }}>Row Per Page: </div></Grid>
                                    <Grid item>
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard-label"
                                                value={row}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="5">5</MenuItem>
                                                <MenuItem value="10">10</MenuItem>
                                                <MenuItem value="20">20</MenuItem>
                                                <MenuItem value="30">30</MenuItem>
                                                <MenuItem value={toString(all.length)}>All</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                            </div>
                        </NavPaper>
                        <BackPaper>
                            {all.length === 0 ?
                                <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                                    <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                                    <Grid item><div className="errorMessage">User not found!</div></Grid>
                                </Grid> :
                                <>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>S.No</TableCell>
                                                <TableCell >Group Name</TableCell>
                                                <TableCell >Description</TableCell>
                                                <TableCell >Admin</TableCell>
                                                <TableCell >Members</TableCell>
                                                <TableCell >Users</TableCell>
                                                <TableCell >Posts</TableCell>
                                                <TableCell >Create</TableCell>
                                                <TableCell align='center'>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {all.map((element, index) => {
                                                let date, datearr, newDate;
                                                try {
                                                    date = new Date(element.createdAt);
                                                    datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
                                                    newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                                                        datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                                                        date.toISOString().substring(0, 10).split("-")[0];
                                                } catch (err) {
                                                    console.error(err);
                                                    // console.log('element.createdAt', element.createdAt)
                                                    newDate = 'InvalidDate';
                                                }
                                                return (<>
                                                    <TableRow key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{element.group_name}</TableCell>
                                                        <TableCell>{element.group_description && element.group_description.slice(0, 30)}...</TableCell>
                                                        <TableCell align='left'>
                                                            <NavLink to={'/admin/users/' + element.userId} >
                                                                <div className="viewText">
                                                                    View admin
                                                                </div>
                                                            </NavLink>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {element.group_members && element.group_members.length > 0 ?
                                                                element.group_members.length : 'New Group'
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="images">
                                                                {element.user && element.user.map((element, index) => (
                                                                    <>
                                                                        {
                                                                            element.profilePic !== '' ? (
                                                                                <img src={`${element.profilePic && element.profilePic.startsWith("https") ? element.profilePic : BASE_URL + 'uploads/images/profiles/' + element.profilePic}`} alt="user" style={{ width: "30px", height: "30px", borderRadius: '50px' }} />
                                                                            ) : (
                                                                                <img src="/images/blank_pic.png" style={{ width: "30px", height: "30px", borderRadius: "50px" }} alt="" />

                                                                            )
                                                                        }
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {element.group_posts && element.group_posts.length > 0 ?
                                                                element.group_posts.length : 'New Group'
                                                            }
                                                        </TableCell>
                                                        <TableCell > {moment(element.createdAt).fromNow()} </TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                                                                <Grid item>
                                                                    <Tooltip title="view profile" >
                                                                        <NavLink to={'/admin/all-groups/' + element._id} ><RemoveRedEye className='visibilityIc' /></NavLink>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title="Remove User From Website" >
                                                                        <Delete className="userListDelete" onClick={() => { handleDeleteOpen(element._id) }} />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                                )

                                            })}
                                        </TableBody>
                                    </Table>

                                </>
                            }

                            {/* dilogue for delete group */}
                            <Dialog open={delopen} aria-labelledby="alert-dialog-slide-description" aria-describedby="alert-dialog-slide-description">
                                <DialogTitle id="alert-dialog-slide-description">
                                    {"You want to delete this group?"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        All data and posts have been deleted if you delete this group.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>No</Button>
                                    <Button onClick={() => { deleteGroupData(id) }} >Yes</Button>
                                </DialogActions>
                            </Dialog>
                        </BackPaper>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
                            <div>
                                <Pagination count={Math.ceil(all.length / row)} page={Math.floor(skip / row) + 1} variant="outlined" onChange={skipPagination} shape="rounded" />
                            </div>
                        </div>
                    </>

                )}

        </>
    )
}

export default Group









