import { AccountCircle, AccountCircleOutlined, AddAPhoto, AddToPhotos, BuildOutlined, Close, Comment, ConstructionOutlined, Delete, Email, EmailOutlined, Error, Favorite, FreeBreakfastSharp, PersonOutlineOutlined, RemoveRedEye, ScoreboardOutlined, WorkspacePremium, WorkspacePremiumOutlined } from '@mui/icons-material'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import APIFunctions from '../../ApiFunction'
import Toptag from '../../components/topTag/Toptag'
import Loader from '../../components/loader/Loader'
import { BASE_URL } from '../../Api'
import { BackPaper, DetailData, DetailLabel } from '../../components/Styles'
import moment from 'moment'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


const GroupDetail = () => {
    const router = useParams()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [obj, setObj] = useState({})
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const [value, setValue] = React.useState('1');
    const [delopen, setDelOpen] = useState(false);
    const [id, setId] = useState()



    const handleDeleteOpen = (id) => {
        setId(id)
        setDelOpen(true);
    };

    const handleClose = () => {
        setDelOpen(false);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const getAll = async () => {
        try {
            let obj = {
                id: router.id,
            }
            const result = await APIFunctions.getGroupDetails(obj);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate('/admin')
                    toast.info("Session expired")
                }
                toast.error(result.data.messsage)
            } else {
                setObj(result.data.data[0])
                await delay(1000);
                setIsLoading(false)
            }

        } catch (error) {
            console.log(error.message)
        }
    }



    const deleteGroupPostData = async (id) => {
        try {
            let obj = {
                id: id
            }
            const result = await APIFunctions.deleteGroupPost(obj);
            if (!result.data.status) {

                if (result.data.code === 401) {
                    localStorage.clear('admintoken');
                    sessionStorage.clear('admintoken');
                    navigate('/admin')
                    toast.info("Session expired")
                }
                toast.error(result.data.message)
            } else {
                setDelOpen(false);
                getAll();
                toast.success("Group post has been delete successfully!")
            }
        } catch (error) {
            console.log(error.message)
        }
    }


    useEffect(() => {
        getAll()
    }, [])

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <Toptag />
                    <ToastContainer />
                    <BackPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={8}>
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                                <Tab label={`Posts (${obj.group_posts && obj.group_posts.length})`} value="1" className="tabs" />
                                                <Tab label={`Members (${obj.group_members && obj.group_members.length})`} value="2" className="tabs" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1" style={{ padding: "0px" }}>
                                            <div className='group-posts'>
                                                {obj.posts && obj.posts.map((element, index) => (
                                                    <div className='posts' key={index + 1}>
                                                        <div className='heading'>{element.title}</div>
                                                        <div className='desc'>{element.description}</div>
                                                        <div className='d-flex gap-2 w-100 text-end justify-content-end align-items-center'>
                                                            <div className='content'> {element.commentPoint === 0 ? null : <>Comment {element.commentPoint}</>}</div>
                                                            <div className='content'>{element.likes.length === 0 ? null : <>Likes {element.likes.length}</>}</div>
                                                            <div className='content'>
                                                                <Tooltip title="remove post from group">
                                                                    <Delete className='userListDelete' onClick={()=>{handleDeleteOpen(element._id)}} />
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="2" style={{ padding: "0px" }}>
                                            <div className='group-posts'>
                                                {obj.users && obj.users.map((element, index) => (
                                                    <div className='posts' key={index + 1}>
                                                        <div className='heading'>
                                                            {element.name}
                                                        </div>
                                                        <div className='desc'>{element.bio}</div>
                                                        <div className='d-flex gap-2 w-100 text-end justify-content-end align-items-center'>
                                                            {/* <div className='content'> {element.commentPoint === 0 ? null :<>Comment {element.commentPoint}</> }</div>
                                                            <div className='content'>{element.likes.length === 0 ? null :<>Likes {element.likes.length}</> }</div> */}
                                                            <div className='content'>
                                                                <Tooltip title="view user details">
                                                                    <NavLink to={'/admin/users/' + element._id}>
                                                                        <RemoveRedEye className='visibilityIc' />
                                                                    </NavLink>
                                                                </Tooltip>                                                                </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <div className='detials-card'>
                                    <div className='userDate text-end w-100 mb-4'>Created on {moment(obj.createdAt).format('LLL')}</div>
                                    <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                                        <Grid item>
                                            {obj.group_logo !== '' ? (
                                                <img src={`${BASE_URL}uploads/images/groups/${obj.group_logo}`} alt="user" className='group-detailsImage' />

                                            ) : (
                                                <img src="/images/blank_pic.png" className='user-detailsImage' alt="" />

                                            )}
                                        </Grid>
                                        <Grid item>
                                            <div className='detailHeading'>{obj.group_name}</div>
                                        </Grid>
                                        <Grid item></Grid>
                                        <div className='userDate text-center w-100'>{obj.group_description}</div>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                         {/* dilogue for delete group post */}
                         <Dialog open={delopen} aria-labelledby="alert-dialog-slide-description" aria-describedby="alert-dialog-slide-description">
                                <DialogTitle id="alert-dialog-slide-description">
                                    {"You want to delete this group?"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        All data and posts have been deleted if you delete this group.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>No</Button>
                                    <Button onClick={() => { deleteGroupPostData(id) }} >Yes</Button>
                                </DialogActions>
                            </Dialog>



                    </BackPaper>
                </>}

        </>
    )
}

export default GroupDetail