import React, { useEffect, useState } from 'react'
import { DoneAll, RemoveRedEye } from '@mui/icons-material'
import { Grid, Paper, styled, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import Toptag from '../../components/topTag/Toptag'
import { toast, ToastContainer } from 'react-toastify'
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../../components/loader/Loader'
import APIFunctions from '../../ApiFunction'


const BackPaper = styled(Paper)({
  height: "580px",
  overflow: "auto",
  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
})

const Contact = () => {
  const [all, setAll] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState()

  const getAll = async () => {
    try {
      let result = await APIFunctions.allContact()
      if (result.data.code === 200) {
        setAll(result.data.data)
      } else if (result.data.code === 401) {
        localStorage.clear('admintoken');
        sessionStorage.clear('admintoken');
        navigate("/admin")
        toast.info("Session expired")
      }
    } catch (err) {
      console.error(err.message)
    }
  }
  useEffect(() => {
    setIsLoading(false)
    getAll()
  }, [])


  return (
    <>
      {isLoading ? <Loader /> :
        <>
          <Toptag />
          <ToastContainer />
          <BackPaper>
            {all.length === 0 ?
              <Grid container spacing={2} style={{ marginTop: "30px" }} direction="column" justifyContent="space-evenly" alignItems="center">
                <Grid item><img src="/images/no-data.png" alt="error-image" style={{ height: "150px", width: "200px" }} /></Grid>
                <Grid item><div className="errorMessage">Zero Contact Messages!</div></Grid>
              </Grid> :
              <>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className='tableHead'>
                      <TableCell>Index</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Contact</TableCell>
                      <TableCell align="center">Message On</TableCell>
                      <TableCell align="center">Message Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {all.map((element, index) => {
                      let date, datearr, newDate;
                      try {
                        date = new Date(element.createdAt);
                        datearr = ["Jan", "Feb", "March", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
                        newDate = date.toISOString().substring(0, 10).split("-")[2] + " " +
                          datearr[parseInt(date.toISOString().substring(0, 10).split("-")[1]) - 1] + " " +
                          date.toISOString().substring(0, 10).split("-")[0];
                      } catch (err) {
                        console.error(err);
                        // console.log('element.createdAt', element.createdAt);
                        newDate = 'InvalidDate';
                      }
                      return (
                        <TableRow  key={index + 1} className={index % 2 === 0 ? 'rowBg' : null}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{element.name}</TableCell>
                          <TableCell>{element.email}</TableCell>
                          <TableCell>{element.contact}</TableCell>
                          <TableCell align="center">{newDate}</TableCell>
                          <TableCell align="center">
                            {element.isNewMessage === true ?
                              <Tooltip title="new message">
                                <div className='newMessage'>
                                  <div>New Message</div>
                                </div>
                              </Tooltip>
                              :
                              <Tooltip title="you read this message">
                                <DoneAll style={{ color: 'grey', fontSize: '40px' }} />
                              </Tooltip>
                            }</TableCell>
                          <TableCell align="center">
                            <Tooltip title="view message detail" >
                              <NavLink to='/admin/contact-us-messages/details' state={{ id: element._id }}><RemoveRedEye className='visibilityIc' /></NavLink>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

              </>}

          </BackPaper>
        </>
      }

    </>
  )
}

export default Contact